.fromDrawer .MuiDrawer-paper {
  min-width: 400px !important;
}

.fromDrawerMax .MuiDrawer-paper {
  min-width: 60% !important;
}

@media (max-width: 1200px) {
  .fromDrawer .MuiDrawer-paper {
    min-width: 100% !important;
  }

  .fromDrawerMax .MuiDrawer-paper {
    min-width: 100% !important;
  }
}